import { render, staticRenderFns } from "./LangSwitch.vue?vue&type=template&id=653fb4c2&scoped=true&"
import script from "./LangSwitch.vue?vue&type=script&lang=js&"
export * from "./LangSwitch.vue?vue&type=script&lang=js&"
import style0 from "./LangSwitch.vue?vue&type=style&index=0&id=653fb4c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653fb4c2",
  null
  
)

export default component.exports