<template>
  <div id="index">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
#index {
  height: 100%;
  width: 100%;
}
</style>
